<template>
  <b-card>
    <b-row>
      <b-col md="4" class="pb-1">
        <b-button
          class="w-100"
          variant="outline-primary"
          :to="{ name: 'organization-metadata-add' }"
          :disabled="isDisabled"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Metadata_Option') }}
        </b-button>
      </b-col>

      <b-col md="4"  class="pb-1">
        <b-button
          class="w-100"
          variant="outline-info"
          :to="{ name: 'organization-metadata-upload' }"
          :disabled="isDisabled"
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
          />
          {{ $t('bulkMetadataUpload') }}
        </b-button>
      </b-col>

      <b-col md="4">
        <b-form-group>
          <b-form-input
            v-model="searchQuery"
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
          />
        </b-form-group>
      </b-col>

      <b-col>
          <filters
            :metadataFilterShow="true"
            @changeFilter="updateFilter"
            @setAddMetadataButtonStatus="setAddMetadataButtonStatus"
          />
        </b-col>

      <b-col cols="12">
        <b-table
          ref="refMetadataListTable"
          :items="metadataOptionsPaginated"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty=""
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(actions)="data">
            <div class="flex flex-column">
              <!-- <ViewButton /> -->
              <EditButton @clicked="router.push({name: 'organization-metadata-edit', params: { id: metadataFilter, index: data.item.index }})"/>
              <DeleteButton v-b-modal="`modal ${data.item.index}`"/>
              <b-modal
                :id="`modal ${data.item.index}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteMetadata(data.item.index)"
              >
                {{ $t('message.confirm_delete_metadata_option') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="perPageOptions"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ $tc('message.paginationText', 0, { from: `${((currentPage * perPage) - perPage) + (metadataOptionsPaginated.length ? 1 : 0)}`, to: `${metadataOptionsPaginated.length + ((currentPage * perPage) - perPage)}`, total: `${metadataOptionsFiltered.length}` }) }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="metadataOptionsFiltered.length"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import useMetadataList from './useMetadataList'
import Filters from "@/views/organization/Filters.vue";

export default {
  components: {
    BTable,
    BPagination,
    EditButton,
    DeleteButton,
    BModal,
    Filters,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const { router } = useRouter()
    const {
      fetchMetadataOptions,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      totalMetadata,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteMetadata,
      isLoading,
      metadataFilter,
      metadataOptionsFiltered,
      metadataOptionsPaginated,
      isDisabled,
      updateFilter,
      setAddMetadataButtonStatus,
    } = useMetadataList()

    onMounted(() => {
      fetchMetadataOptions()
    })

    return {
      fetchMetadataOptions,
      router,
      tableColumns,
      searchQuery,
      sortBy,
      isSortDirDesc,
      deleteMetadata,
      totalMetadata,
      currentPage,
      perPageOptions,
      perPage,
      isLoading,
      updateFilter,
      metadataOptionsFiltered,
      metadataFilter,
      metadataOptionsPaginated,
      isDisabled,
      setAddMetadataButtonStatus,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.process-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.form-group {
  label {
    font-size: 0.85rem;
    font-weight: 400;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
