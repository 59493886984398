import { ref, watch, computed } from '@vue/composition-api'
import useNotifications from "@/composables/useNotifications";
import i18n from '@/libs/i18n';
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useMetadataList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications();
  const { handleError } = useCommon()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const metadataOptions = ref([])
  const metadataOptionsMapped = ref([])
  const metadataOptionsFiltered = ref([])
  const metadataOptionsPaginated = ref([])

  // Table Headers
  const tableColumns = computed(() => {
    return [
      { key: 'option', label: i18n.t('message.tableHeader.option'), sortable: true },
      { key: 'Actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })

  const totalMetadata = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('option')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)
  const isDisabled = ref(true)
  const metadataFilter = ref("")
  const collection = 'metadata'

  const fetchMetadataOptions = async () => {
    isLoading.value = true

    if (!metadataFilter.value) {
      resetMetadataLists()
      isLoading.value = false
      return
    }

    try {
      const query = { _id: ObjectId(metadataFilter.value) }
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')

      metadataOptions.value = item.options
      metadataOptionsMapped.value = metadataOptions.value?.map((e, i) => ({ index: i, option: e }))
      isSortDirDesc.value = false
      searchQuery.value = ""
      sortMetadata()
      filterAndPaginate()
    } catch (error) {
      console.log(error)
      handleError({ error, defaultMessage: i18n.t('message.metadata_fetch_error') })
    } finally {
      isLoading.value = false
    }
  }

  const deleteMetadata = async (index) => {
    metadataOptions.value?.splice(index, 1)

    try {
      const query = { _id: ObjectId(metadataFilter.value) }
      const payload = { options: metadataOptions.value }
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      fetchMetadataOptions()
      showSuccessMessage(i18n.t('message.metadata_remove'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.something_went_wrong'))
    }
  }

  const sortMetadata = () => {
    metadataOptionsMapped.value?.sort(function (a, b) {
      if (a.option > b.option) {
        if (isSortDirDesc.value) return -1
        return 1
      }
      if (a.option < b.option) {
        if (isSortDirDesc.value) return 1
        return -1
      }
      return 0
    })
  }

  const filterAndPaginate = () => {
    metadataOptionsFiltered.value = searchQuery.value
      ? metadataOptionsMapped.value?.filter(e => e.option?.toLowerCase().includes(searchQuery.value.toLowerCase()))
      : metadataOptionsMapped.value
    if (currentPage.value !== 1) currentPage.value = 1
    else metadataOptionsPaginated.value = metadataOptionsFiltered.value?.slice((currentPage.value * perPage.value) - perPage.value, currentPage.value * perPage.value)
  }

  const resetMetadataLists = () => {
    metadataOptions.value = []
    metadataOptionsMapped.value = []
    metadataOptionsFiltered.value = []
    metadataOptionsPaginated.value = []
    currentPage.value = 1
  }

  const updateFilter = (data) => {
    metadataFilter.value = data.metadataFilter;

    fetchMetadataOptions();
  }

  const setAddMetadataButtonStatus = (status) => {
    isDisabled.value = !status
  }

  watch([currentPage, perPage], () => {
    metadataOptionsPaginated.value = metadataOptionsFiltered.value?.slice((currentPage.value * perPage.value) - perPage.value, currentPage.value * perPage.value)
  })

  watch(isSortDirDesc, () => {
    sortMetadata()
    filterAndPaginate()
  })

  watch(searchQuery, () => {
    filterAndPaginate()
  })

  return {
    fetchMetadataOptions,
    tableColumns,
    perPage,
    currentPage,
    totalMetadata,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    deleteMetadata,
    isLoading,
    metadataFilter,
    metadataOptionsFiltered,
    metadataOptionsPaginated,
    isDisabled,
    updateFilter,
    setAddMetadataButtonStatus,
  }
}